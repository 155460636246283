<template>
    <div>
        <!-- text字符串类型 -->
        <div v-if="itemData.type == 'text'">
            <a-input v-model:value="value" v-if="itemData.addon" :addon-after="itemData.addon" @change="getInputValue"></a-input>
            <a-input v-model:value="value" v-else @change="getInputValue"></a-input>
        </div>
        <!-- number数字类型 -->
        <div v-if="itemData.type == 'number'">
            <a-input type="number" 
                v-model:value="value" 
                v-if="itemData.addon" 
                :addon-after="itemData.addon"
                @change="getInputValue">
            </a-input>
            <a-input type="number" v-model:value="value" v-else @change="getInputValue"></a-input>
        </div>
        <!-- 图片类型 -->
        <div v-if="itemData.type == 'image'">
            <kd-img-select 
                :src="value" 
                @change="getValue">
            </kd-img-select>
        </div>
        <!-- 地图组件 -->
        <div v-if="itemData.type == 'map'">
            <kd-map style="width:100%" :value="value" @change="getMapValue"> </kd-map>
        </div>

        <!-- 富文本编辑器 -->
        <div v-if="itemData.type == 'editor'">
            <tinymce-editor v-model="value" @change="getValue" :uniid="itemData.key"></tinymce-editor>
        </div>

        <!-- 复选框 -->
        <div v-if="itemData.type =='checkbox'">
            <a-checkbox-group v-model:value="value" @change="getInputValue">
                <a-checkbox v-for="(item,index) in itemData.options" :key="index" :value="item.value" >
                    {{item.label}}
                </a-checkbox>
            </a-checkbox-group>
        </div>

        <!-- 开关 -->
        <div v-if="itemData.type =='switch'">
            <kd-switch :value="value" :active-value="1" :inactive-value="0" @change="getValue"></kd-switch>
        </div>

        <!-- 单选框 -->
        <div v-if="itemData.type =='radio'">
            <a-radio-group v-model:value="value" @change="getInputValue">
                <a-radio v-for="(val,ind) in itemData.options" :key="ind" :value="val.value">
                    {{val.label}}
                </a-radio>
            </a-radio-group>
        </div>

        <!-- 选择框 -->
        <div v-if="itemData.type == 'select'">
            <a-select v-model:value="value" @change="getValue">
                <a-select-option v-for="(item,index) in itemData.options" :key="index" :value="item.value">
                    {{item.label}}
                </a-select-option>
            </a-select>
        </div>
        <div v-if="itemData.type == 'select-mutil'">
            <a-select v-model:value="value" @change="getValue" mode="multiple">
                <a-select-option v-for="(item,index) in itemData.options" :key="index" :value="item.value">
                    {{item.label}}
                </a-select-option>
            </a-select>
        </div>

        <!-- 颜色选择器 -->
        <div v-if="itemData.type =='color'">
            <kd-color :fixd="true" v-model="value" @getVal="getValue"></kd-color>
        </div>
        
        <div v-if="itemData.type =='textarea'">
            <a-textarea v-model:value="value" :rows="8" @change="getInputValue"/>
        </div>


        <!-- 可隐藏输入框 -->
        <div v-if="itemData.type =='hide-text'">
            <kd-hide-sensitivity-input 
                :value="value" 
                @getValue="getValue">
            </kd-hide-sensitivity-input>
        </div> 
        <!-- 证书上传组件 -->
        <div v-if="itemData.type =='upload-cert'">
            <div v-if="itemData.hideUrl">
                <upload-cert :hideUrl="false" :value="value" @change="getValue"></upload-cert>
            </div>
            <div v-else>
                <upload-cert :value="value" @change="getValue"></upload-cert>
            </div>
        </div>

        <!-- 带按钮提示 -->
        <div v-if="itemData.type =='text-btn'">
            <a-space>
                <a-input v-model:value="value" style="width:400px" @change="getInputValue"></a-input>
                <a-button v-if="itemData.tipsImg" @click="showTemplateTest(itemData.label,itemData.tipsImg)">查看模板消息示例</a-button>
            </a-space>
        </div>
    </div>
</template>
<script>
import kdColor from '@/components/public/kd-color.vue'
import { reactive, toRefs,watch } from 'vue'
export default {
    name:"com-power-form-item",
    components:{
        kdColor
    },
    props:{
        itemData:{
            type:Object,
        },
        modelValue:{
            type:[String,Array,Object,Number],
            default:""
        }
    },
    setup(props,context) {
        const _d = reactive({
            value:props.modelValue,
        })

        //监听当前绑定的值的数据变化 主要用户初始化监听v-model传入的值
        watch(() => props.modelValue,(value) => {
                _d.value  = value
            }
        )

        function exportData(e=null){
            _d.value = e ?? _d.value
            context.emit("update:modelValue",_d.value)
        }

        //输入框获取数据
        const getInputValue = (e) => exportData()
        const getValue = (e) => exportData(e)

        const getMapValue = (e)=>{
            _d.value = e.longitude+','+e.latitude
            exportData()
        }
        const showTemplateTest = (title,img)=> context.emit("btnClick",{title,img})
        return{
            ...toRefs(_d),
            getInputValue,
            getValue,
            getMapValue,
            showTemplateTest
        }
    },
}
</script>