<template>
    <!-- 万能表单 -->
    <div class="power-form">
        <a-form :label-col="{span:3}" :wrapper-col="{span:12}">
            <div v-if="formType == 'form'">
                <a-form-item v-for="(item,index) in form" :key="index" :label="item.label">
                    <com-power-form-item :itemData="item" v-model="item.value" @btnClick="btnClick"></com-power-form-item>
                    <!-- 充值赠送规则 -->
                    <div v-if="item.type =='recharge-rule'">
                        <div v-for="(v,ind) in item.value" :key="ind">
                            <span>规则{{ind+1}}：</span>
                            <a-space>
                                <span>充</span><a-input v-model:value="v.recharge"></a-input>
                                <span>送</span><a-input v-model:value="v.send"></a-input>
                                <i class="ri-delete-bin-line ri-xl ri-top" v-if="ind>0" @click.prevent="removeRechargeRule(index,ind)"></i>
                            </a-space>
                        </div>
                        <div class="recharge-rule-add" @click="addRechargeRule(index)"><i class="ri-add-line ri-xl ri-top"></i></div>
                    </div>
                    <!-- 有说明 -->
                    <div class="f12 lh16" v-if="item.tips" >
                        <span v-html="item.tips"></span>
                    </div>
                </a-form-item>
            </div>
            <div v-if="formType == 'card'">
                <a-card v-for="(val,ind) in form" :key="ind" :title="val.title" class="mb12">
                    <a-form-item v-for="(item,index) in val.param" :key="index" :label="item.label">
                        <com-power-form-item :itemData="item" v-model="item.value"></com-power-form-item>
                        <!-- 有说明 -->
                        <div class="f12 lh16" v-if="item.tips" >
                            <span v-html="item.tips"></span>
                        </div>
                    </a-form-item>
                </a-card>
            </div>

            <div v-if="formType =='check'">
                <div v-for="(item,index) in formData" :key="index">
                    <div v-if="item.show">
                        <a-form-item :label="item.label" v-if="form[item.show.key] == item.show.value">
                            <com-power-form-item :itemData="item" v-model="form[item.key]" @btnClick="btnClick"></com-power-form-item>
                        </a-form-item>
                    </div>
                    <a-form-item :label="item.label" v-else>
                        <com-power-form-item :itemData="item" v-model="form[item.key]" @btnClick="btnClick"></com-power-form-item>
                    </a-form-item>
                </div>
            </div>

            <a-form-item :wrapper-col="{offset:3}">
                <com-submit-btn @success="submitSave"></com-submit-btn>
            </a-form-item>
        </a-form>
    </div>    
</template>
<script>
import { reactive, toRefs } from 'vue'
import set from "@/api/set.js"
import kdColor from '@/components/public/kd-color.vue'
import comPowerFormItem from './com-power-form-item.vue'
export default {
    name:"com-power-form",
    props:{
        formData:{
            type:[Array,Object]
        },
        //表单类型 form 普通表单  card 带card表单 check带判断的表单
        formType:{
            type:String,
            default:"form"
        }
    },
    components:{
        kdColor,
        comPowerFormItem
    },
    setup(props,context) {
        const _d = reactive({
            form:[]
        })
        initData()

        function initData(){
            let data = props.formData , keys = [] ,fy =props.formType
            if( fy == 'form' ){
                keys = getKeys(data)
                set.getFarmSet(keys).then(res=>{
                    _d.form = setKeys(data,res)                
                })
            }
            if( fy == 'card' ){
                data.forEach(item=>{
                    keys = keys.concat(getKeys(item.param))
                })
                set.getFarmSet(keys).then(res=>{
                    data.map(v=>{
                        v.param = setKeys(v.param,res)
                    })
                    _d.form = data                
                })
            }

            if( fy == 'check' ){
                keys = getKeys(data)
                set.getFarmSet(keys).then(res=>{
                    let _form = setKeys(data,res)
                    _form.forEach(val=>{
                        _d.form[val.key] = val.value
                    })
                })
            }
        }

        //获取全部的key
        function getKeys(data){
            let keys = []
            data.forEach(val=>{
                if( typeof val.key =='string'){
                    keys.push(val.key)
                }else{
                    val.key.forEach(v=>{
                        keys.push(v)
                    })
                }
            })
            return keys
        }

        function setKeys(data,res){
            data.forEach(item=>{
                //当组件类型是地图时
                if( item.type == "map" ){
                    if( typeof item.key == 'object'){
                        let arr = []
                        item.key.forEach(val=>{
                            arr.push(res[val])
                        })
                        item.value  = arr.join(',')
                    }
                }else{//其他类型组件
                    if( typeof item.key =='string'){
                        item.value = res[item.key] ? getJsonData(item.dataType,res[item.key]) :getNullData(item.dataType)
                    }
                }
            })
            return data
        }


        //当获取数据接口没有返回某个值时，将该字段赋值为当前对应类型的值
        function getNullData(type){
            let value = ""
            if( type =='string') value=""
            if( type =='array' || type =='json') value=[]
            return value
        }

        //数据类型转换
        function getJsonData(type,data){
            let value = data
            if( type == "json" ){
                if( data ) value = JSON.parse(data)
            }
            return value
        }
        
        //保存数据
        function submitSave(){
            let param = {} , form = JSON.parse(JSON.stringify(_d.form) ) ,fy = props.formType
            const getSubKey = (data)=>{
                data.forEach(item=>{
                    //经纬度类型需要特殊处理
                    if( item.type == "map"){
                        if( item.value ){
                            let lnglat = item.value.split(',')
                            item.key.forEach((val,ind)=>{
                                param[val] = lnglat[ind]
                            })
                        }
                    }else{
                        //json数据类型需要将数据json.stringify后再进行提交操作
                        if( item.dataType == "json" ){  
                            param[item.key] = JSON.stringify(item.value)
                        }else{
                            param[item.key] = item.value
                        }
                    }
                    
                })
            }
            if( fy == 'form') getSubKey(form)
            if( fy == 'card'){
                form.forEach(item=>{
                    getSubKey(item.param)
                })
            }
            if( fy == 'check'){
                props.formData.forEach(item=>{
                    //json数据类型需要将数据json.stringify后再进行提交操作
                    if( item.dataType == "json" ){  
                        param[item.key] = JSON.stringify(_d.form[item.key])
                    }else{
                        param[item.key] = _d.form[item.key]
                    }
                })
            }
            set.setFarmSet(param)
        }

        const removeRechargeRule = (index,ind)=> _d.form[index].value.splice(ind,1)
        const addRechargeRule = (index)=>{
            console.log(_d.form[index].value);
            
            if( !_d.form[index].value ) {
                _d.form[index].value = []
            }

            _d.form[index].value.push({recharge:0,send:0})
        }
        const btnClick = (e)=> {
            context.emit("btnClick",e)
        }
        return{
            ...toRefs(_d),
            submitSave,
            removeRechargeRule,
            addRechargeRule,
            btnClick
        }
    },
}
</script>
<style lang="scss" scoped>
.recharge-rule-add{
    width: 100%;
    text-align: center;
    height:24px;
    line-height: 24px;
    border: 1px dashed #999;
    margin-top: 12px;
    cursor: pointer;
}
</style>